import React from 'react';
import Helmet from 'react-helmet';
import { Layout, Container } from 'layouts';
import styled from '@emotion/styled';
import { Header, UpcomingEvents, Title, Button } from 'components';
import theme from '../../config/theme';
import i18n from '../i18n/i18n';

const Center = styled.div`
  text-align: center;
  padding: 2rem;
`;

const EventContainer = styled.div`
  background: ${theme.colors.white.light};
  padding: 6.5rem 0;
  margin-top: 4rem;
  text-align: center;

  @media (max-width: ${theme.breakpoints.s}) {
    padding: 4rem 0;
  }
`;

const ApplicationComplete = ({
  pageContext: {
    locale,
    defaultSlug,
    supportedLangs,
  }
}) => {

  i18n.changeLanguage(locale)

  return (
    <Layout
      locale={locale}
      defaultSlug={defaultSlug}
      supportedLangs={supportedLangs}
    >
      <Helmet title={i18n.t("applicationCompletePage.titleForOgp")} />
      <Header title={i18n.t("applicationComplete.pageTitle")} />
      <Container type="big">
        <Center>
          <h1>{i18n.t("applicationCompletePage.applicationStatus")}</h1>
          <p>{i18n.t("applicationCompletePage.thanksForApplication")}</p>
          <p>
            <Button to="/" title={i18n.t("applicationCompletePage.backToHome")} />
          </p>
        </Center>
      </Container>
      <EventContainer>
        <Container type="big">
          <Title title="Events" />
          <UpcomingEvents />
          <Button title={i18n.t("button.seeAllEvents")} to="/events" />
        </Container>
      </EventContainer>
    </Layout>
  )
}

export default ApplicationComplete;
